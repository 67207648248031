import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { addproduct, getCartItems } from "../../redux/action";
import { Link } from "react-router-dom";
import { AiOutlineHeart } from "react-icons/ai";
import { FaTag, FaShoppingCart } from "react-icons/fa";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ProductCard = () => {
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const productss = useSelector((state) => state.products);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true); // Set loading to true before the API call
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/get`
        );
        // console.log("productlist",response.data)
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
        setError("Failed to load products.");
      } finally {
        setLoading(false); // Set loading to false after the API call
      }
    };

    fetchProducts();
  }, []);

  const getVisibleProducts = () => {
    return products.slice(0, 8);
  };

  const visibleProducts = getVisibleProducts();

  // const filterArrayProduct = (arr) =>{
  //   return arr.map((product) => {
  //     let prices = product.prices;
  //     let x;

  //     if (prices.length === 1) {
  //       x = prices[0];
  //     } else {
  //       x = prices.reduce((prev, curr) =>
  //         prev.sale_price > curr.sale_price ? prev : curr
  //       );
  //     }

  //     return {
  //       ...product,
  //       lowprice: x,
  //     };
  //   });
  // };

  // const productPriceArray = filterArrayProduct(visibleProducts);

  function filterarrayProduct(products) {
    return products.map((product) => {
      // Extract the lowest price from configurations
      const configurations = product.prices.flatMap(
        (item) => item.configurations
      );

      const lowestPriceConfig = configurations.reduce((min, config) => {
        if (
          !min ||
          (config.sale_price &&
            parseFloat(config.sale_price) < parseFloat(min.sale_price))
        ) {
          return config;
        }
        return min;
      }, null);

      // Return a new product object with the extracted configurations and lowest price
      return {
        ...product,

        LowestPrice: lowestPriceConfig ? lowestPriceConfig.sale_price : null, // Set LowestPrice
        OldPrice: lowestPriceConfig ? lowestPriceConfig.old_price : null,
      };
    });
  }

  const productArray = filterarrayProduct(visibleProducts);
  console.log("prices", productArray);
  const renderStars = (rating, maxRating = 5) => {
    const stars = Array.from({ length: maxRating }, (_, index) => {
      const currentRating = index + 1;
      if (rating >= currentRating) {
        return <FaStar key={index} className="text-gray-600" />;
      } else if (rating >= currentRating - 0.5) {
        return <FaStarHalfAlt key={index} className="text-gray-600" />;
      } else {
        return <FaRegStar key={index} className="text-gray-600" />;
      }
    });
    return <div className="flex">{stars}</div>;
  };

  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`);
  };

  const calculateDiscount = (salePrice, oldPrice) => {
    // console.log(typeof(salePrice),typeof(oldPrice))
    let saleP = +salePrice;
    let oldP = +oldPrice;

    // console.log(oldPrice>salePrice?`it correct ${salePrice} ${oldPrice}`:`it is not correct ${salePrice} ${oldPrice}`)
    if (salePrice != undefined && oldPrice != undefined && oldP > saleP) {
      return Math.round(((oldP - saleP) / oldP) * 100);
    }
    return 0;
  };

  return (
    <div className="bg-green-50 mx-auto p-2 sm:p-4 md:p-6 lg:p-10 xl:p-10 xl:pt-2">
      <div className="flex text-center justify-between items-center mb-4">
        <p className="font-bold text-xl">Top Selling Product</p>
        <Link to="/products">
          <button className="text-[#b22222] items-center space-x-2 p-2 rounded-lg hover:bg-[#b22222] hover:text-white">
            See all
          </button>
        </Link>
      </div>
      <div
        className={`grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 ${
          !showAll ? "xl:grid-cols-4" : "xl:grid-cols-4"
        }`}
      >
        {loading
          ? Array.from({ length: 8 }).map((_, index) => (
              <div
                key={index}
                className="bg-white shadow-sm border-2 rounded-lg p-4"
              >
                <Skeleton height={150} />
                <Skeleton height={20} width="80%" className="mt-2" />
                <Skeleton height={15} width="60%" className="mt-1" />
                <Skeleton height={15} width="40%" className="mt-1" />
                <Skeleton height={15} width="40%" className="mt-1" />
              </div>
            ))
          : productArray.map((product) => (
              <div
                key={product.id}
                onClick={() => handleProductClick(product.id)}
                className="group relative bg-white shadow-sm border-2 rounded-lg overflow-hidden transform transition-transform duration-700 ease-in-out hover:scale-[1.02]"
              >
                <Link to={`/products/${product.id}`}>
                  <div className="relative">
                    <img
                      src={`https://uvfolderking.com/suresop/storage/app/public/${product.featured_image}`}
                      alt={product.name}
                      className="object-cover w-full h-48"
                    />
                    <div className="absolute top-1 left-1 bg-[#b22222] text-white px-2 py-1 rounded-full text-xs flex items-center">
                      <FaTag className="mr-1" />
                      {calculateDiscount(product.LowestPrice, product.OldPrice)}
                      % OFF
                    </div>
                  </div>
                  <div className="p-4">
                    <p className="text-start text-lg font-semibold mb-2">
                      {product.name.slice(0, 50)}
                    </p>
                    <p className="text-sm text-gray-600 mb-2 sm:mb-1">
                      {product.description.slice(0, 40)}
                    </p>
                    <div className="text-sm text-gray-600 mb-2 sm:mb-1">
                      {renderStars(product.rating)}
                    </div>
                    <div className="flex items-center space-x-2 mb-2 sm:mb-1">
                      <span className="text-sm font-bold text-green-500">
                        ₹{product.LowestPrice}
                      </span>
                      <span className="text-sm text-gray-500 line-through">
                        ₹{product.OldPrice}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
      </div>
    </div>
  );
};

export default ProductCard;
