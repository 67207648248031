import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  addproduct,
  getCartItems,
  setUserProfile,
  userAddresses,
} from "./../../redux/action";
import { useNavigate } from "react-router-dom";

const OrderSummary = () => {
  // Static cart items

  const [showAnimation, setShowAnimation] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(-1);
  const [selectedPayment, setSelectedPayment] = useState("COD");
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [addresses, setaddresses] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [activeSection, setActiveSection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [payementType, setPayementType] = useState("cod");
  const [orderSuccess, setOrderSuccess] = useState(false);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [cartId, setCartId] = useState(-1);

  // const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  // const [addresses,setaddresses]=useState([]);
  //   const [selectedAddressId, setSelectedAddressId] = useState(-1);

  useEffect(() => {
    if (!userId || !token)
      return (
        <>
          <div>Please log in to proceed</div>
        </>
      );

    async function getCartItems() {
      try {
        const response = await axios.get(
          `https://uvfolderking.com/suresop/api/cart/${userId}`
        );
        // console.log(response);
        let products = response.data.cart.products;
        setCartItems(products);

        setCartId(response.data.cart.products[0].pivot.cart_id);
        let sum = 0;
        for (let i = 0; i < products.length; i++) {
          sum += parseInt(products[i].pivot.price * products[i].pivot.quantity);
        }
        console.log("total price", totalPrice, sum);
        setTotalPrice(sum);
        setTotalQuantity(sum);
        // setSelectedAddressId();
        // console.log("carts items is", response.data.cart.products);
      } catch (err) {}
    }
    async function getAddresses() {
      try {
        const response = await axios.get(
          `https://uvfolderking.com/suresop/api/user/shipping-addresses/user_id_${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("addresses are", response.data.addresses);
        setaddresses(response.data.addresses);
        console.log("address", response.data.addresses);
        setSelectedAddressId(response.data.addresses[0].id);
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    }
    getAddresses();
    getCartItems();
  }, []);

  // Calculate total price and item count based on static cart items

  const handleAddressSelect = (e) => {
    const addressId = e.target.value;
  };

  const handlePaymentSelect = (paymentMethod) => {
    setSelectedPayment(paymentMethod);
  };

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };
  const totalAmount = cartItems.reduce((total, product) => {
    const productTotalPrice =
      product.pivot.quantity * parseFloat(product.pivot.price);
    return total + productTotalPrice;
  }, 0);
  console.log(" totalAmount", totalAmount);
  // Prepare the order data
  // const formData = {
  //   username: userProfile.first_name,
  //   mobile: userProfile.mobile_number,
  //   totalItems: totalItems,
  //   totalPrice: totalPrice,
  //   selectedAddress: selectedAddress,
  //   selectedPayment: selectedPayment,
  //   cartItems: cartItems,
  // };
  const orderData = {
    total_items: cartItems.length,
    payment_type: selectedPayment,
    payment_status: "PENDING",
    total_amount: totalPrice,
    net_amount: totalPrice,
    shipping_charges: 0,
    status: "placed",
    user_id: userId,
    shipping_address_id: selectedAddressId,
    order_items: cartItems.map((product) => ({
      product_id: product.pivot.products,
      size: product.pivot.size,
      color: product.pivot.color,
      sales_price: product.pivot.price,
      old_price: product.pivot.old_price,
      vendor_id: product.vendor_id,
      total_price: product.pivot.quantity * parseFloat(product.pivot.price),
      quantity: product.pivot.quantity,
    })),
  };
  // Function to handle placing the order
  const handlePlaceOrder = async () => {
    if (selectedAddressId === -1) {
      alert("Please select a shipping address before placing the order.");
      return;
    }
    console.log(selectedAddressId, orderData);
    // if(selectedAddressId==""){
    //   alert("please select the address")
    //   return;
    // }
    try {
      const response = await axios.post(
        `https://uvfolderking.com/suresop/api/cart/orders_cart`,
        orderData
      );
      setOrderSuccess(true);
      setShowAnimation(true);

      setTimeout(() => {
        setShowAnimation(false);
      }, 5000);
    } catch (error) {
      console.error("error in cart order ", error);
    }
  };

  if (orderSuccess) {
    async function setOrderSuccessed() {
      try {
        const response = await axios.delete(
          `https://uvfolderking.com/suresop/api/cart/${cartId}/clear`
        );
        // console.log(response);

        if (response.status === 200) {
          // show animation

          navigate("/order_sucess_page");
          setOrderSuccess(false);
        }
      } catch (error) {
        console.error("error in cart order ", error);
      }
    }
    setOrderSuccessed();
  }
  const handleaddAddress = () => {
    navigate("/account/newaddress-form");
  };
  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
      {/* Left Section - Address, Cart Items, Payment Options */}
      <div className="md:col-span-2 bg-white p-5 rounded-lg shadow-lg">
        {/* Login Details */}
        <div
          className={`mb-6 shadow-lg ${
            activeSection === "cart" ? "bg-white" : ""
          }`}
        >
          <h2
            className="text-xl font-bold mb-2 cursor-pointer  pt-3 pb-3 pl-3 text-[#b22222]"
            onClick={() => toggleSection("cart")}
          >
            Your Cart Items
            <span className="ml-2  text-[#b22222]">
              {activeSection === "cart" ? "-" : "+"}
            </span>
          </h2>
          {activeSection === "cart" && (
            <div className="md:col-span-2 lg:col-span-2">
              {cartItems.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="flex items-center justify-between bg-white lg:p-4 mb-4 rounded-sm  flex-col sm:flex-row gap-10 border-2 border-red mx-[5px]"
                  >
                    <div className="relative ">
                      <img
                        className=" border w-24 h-24 mr-6 object-cover"
                        src={`https://uvfolderking.com/suresop/storage/app/public/${item.featured_image}`}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col sm:flex-row sm:pl-6 w-full">
                      <div className="sm:w-2/3">
                        <h2 className="text-lg font-semibold text-gray-800">
                          {item.name}
                        </h2>
                        <p className="text-sm text-gray-500 mt-1">
                          Color: {item.pivot.color}
                        </p>
                        <p className="text-sm text-gray-500 ">
                          quantity: {item.pivot.quantity}
                        </p>
                        <p className="text-sm text-gray-500"></p>
                        <div className="flex items-center space-x-4 mt-2">
                          {/* <p className="text-gray-400 line-through">
                          ₹{item.old_price}
                        </p> */}
                          <p className="text-xl font-semibold text-gray-800">
                            ₹{item.pivot.price * item.pivot.quantity}
                          </p>
                          {/* <p className="text-green-600 text-sm">
                          {Math.round(
                            ((item.old_price - item.sale_price) /
                              item.old_price) *
                              100
                          )}
                          % Off
                        </p> */}
                          {/* <p className="text-green-500 text-sm">Coupon applied</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {/* Address Selection */}
        <div
          className={`mb-6 shadow-lg ${
            activeSection === "address" ? "bg-gray-100" : ""
          }`}
        >
          <h2
            className="text-xl font-bold mb-2 cursor-pointer bg-white pt-3 pb-3 pl-3 text-[#b22222]"
            onClick={() => toggleSection("address")}
          >
            Choose Delivery Address
            <span className="ml-2  text-[#b22222]">
              {activeSection === "address" ? "-" : "+"}
            </span>
          </h2>
          {activeSection === "address" && (
            <div className="space-y-4 pl-8 pr-5 ">
              {addresses.map((address, index) => (
                <div
                  key={address.id}
                  className="flex items-center space-x-3  border-b-2 pb-2"
                >
                  <input
                    type="radio"
                    id={`address-${address.id}`}
                    name="address"
                    value={address.id}
                    onChange={handleAddressSelect}
                    onClick={() => {
                      setSelectedAddressIndex(index);
                      setSelectedAddressId(address.id);
                    }}
                    checked={index == selectedAddressIndex}
                  />
                  <label
                    htmlFor={`address-${address.id}`}
                    className="ml-2  mr-5 w-full"
                  >
                    <div className="flex justify-between mb-3">
                      <div className="font-semibold text-lg text-gray-700">
                        {address.full_name}
                      </div>
                      <div className="text-sm text-gray-600">
                        {address.mobile_number}
                      </div>
                    </div>
                    <div className="text-sm text-gray-600 mb-2">
                      {address.full_address} - {address.near_by_address},{" "}
                      {address.city}, {address.state}
                    </div>
                    <div className="text-sm text-gray-600">
                      Pin Code: {address.pin_code}
                    </div>
                  </label>
                </div>
              ))}
              <div className="pb-2 " onClick={() => handleaddAddress()}>
                <div className="bg-[#b22222] flex justify-center items-center text-white lg:pt-3 lg:pb-3 sm:pb-2 sm:pt-2">
                  Add Address
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Payment Options */}
        <div
          className={`mb-6 shadow-lg ${
            activeSection === "payment" ? "bg-gray-100" : ""
          }`}
        >
          <h2
            className="text-xl font-bold mb-2 cursor-pointer bg-white pt-3 pb-3 pl-3  text-[#b22222]"
            onClick={() => toggleSection("payment")}
          >
            Payment Options
            <span className="ml-2  text-[#b22222]">
              {activeSection === "payment" ? "-" : "+"}
            </span>
          </h2>
          {activeSection === "payment" && (
            <div className="space-y-4 pl-8 pb-3">
              <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  id="upi"
                  name="payment"
                  value="upi"
                  onChange={() => {
                    handlePaymentSelect("upi");
                    setPayementType("online_mode");
                  }}
                  checked={payementType === "online_mode"}
                />
                <label htmlFor="upi" className="ml-2">
                  Online Mode
                </label>
              </div>
              <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  id="cod"
                  name="payment"
                  value="cod"
                  onChange={() => {
                    handlePaymentSelect("COD");
                    setPayementType("cod");
                  }}
                  checked={payementType === "cod"}
                />
                <label htmlFor="cod" className="ml-2">
                  Cash on Delivery (COD)
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Right Section - Total Summary */}
      <div className="bg-white sticky top-20 p-6 rounded-lg shadow-lg sticky top-0">
        <h2 className="text-xl font-bold mb-4">Order Summary</h2>
        <div className="space-y-2">
          <div className="flex justify-between">
            <p>Total Items</p>
            <p>{cartItems.length}</p>
          </div>
          <div className="flex justify-between">
            <p>Total Price</p>
            <p className="font-semibold text-blue-500">₹{totalPrice}</p>
          </div>
        </div>
        <hr className="my-4" />
        <button
          className="w-full py-3 bg-[#42a147]  text-white rounded-lg hover:bg-green-800 transition duration-300"
          onClick={handlePlaceOrder}
          disabled={isLoading}
        >
          {isLoading ? "Placing Order..." : "Place Order"}
        </button>
        {/* {orderSuccess && (
          <div className="mt-4 text-green-500 font-bold">
            Your order has been placed successfully!
          </div>
        )} */}
        {showAnimation && (
          <div className="flex flex-col items-center mt-5 animate-fade-in">
            <div className="text-green-500 text-5xl animate-bounce">✔</div>
            <p className="text-gray-800 text-lg mt-2">
              Order Placed Successfully!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSummary;
