import React, { useEffect,useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userAddresses } from "../../../redux/action";
import UserAccount from "./UserAccount";
import { Link } from "react-router-dom";
import { MdAdd,MdMenu } from "react-icons/md";

const UserAddresses = () => {
  const [userAccount, setUserAccount] = useState(true);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const handleDeleteAddress = async (addressId) => {
    alert("Are you sure you want to delete the address");
    try {
      await axios.delete(
        `https://uvfolderking.com/suresop/api/user/shipping-address/user_id_${userId}/address_id_${addressId}`
      );
      dispatch(userAddresses());    } catch (error) {
      console.error("Error while trying to delete:", error);
    }
  };

  const address = useSelector((state) => state.userAddresses);
  const dispatch = useDispatch();

  console.log("the redux address is is is address", address);

  useEffect(() => {
    dispatch(userAddresses());
  }, [dispatch]);

   // Function to toggle UserAccount visibility
   const toggleUserAccount = () => {
    setUserAccount(!userAccount);
  };
  const handleEditButton = (addressId) => {
    navigate(`/account/${addressId}`);
  };

  return (
   
      <div className="flex gap-4 ">
        <div>
        {userAccount ? <UserAccount /> : null}
      </div>
        <div className="flex-grow p-5 mr-10 shadow-lg rounded-md">
        <div className="flex justify-end">
          <MdMenu onClick={toggleUserAccount} className="text-[1.5rem] cursor-pointer" />
        </div>
          <h2 className="text-xl font-semibold pb-5">Manage Addresses</h2>

          <Link to="/account/newaddress-form">
            <div className=" flex items-center gap-5 text-blue-500 border border-2 p-3 font-semibold">
              <div className="font-bold text-[20px] text-[#b22222]">
                <MdAdd />
              </div>
              <div>
                <p className="text-[#b22222]">ADD A NEW ADDRESS</p>
              </div>
            </div>
          </Link>

          {address.addresses?.map((add, index) => (
            <div key={index} className="border-b py-4 px-4 ">
              <div className="flex  justify-between gap-5">
                <p className="bg-gray-100 rounded-lg p-1">Home</p>
                <div className=" flex text-bold text-[17px] cursor-pointer gap-8">
                  {/* <Link to={`/account/${add.id}`}> */}
                    <div
                      onClick={() => handleEditButton(add.id)}
                      className="hover:text-blue-500 "
                    >
                      <p className="text-green-500">Edit</p>
                    </div>
                  {/* </Link> */}
                  <div
                    onClick={() => handleDeleteAddress(add.id)}
                    className="hover:text-red-500"
                  >
                    <p className="text-red-500">Delete</p>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 pt-3">
                <p className="font-semibold">{add.full_name}</p>
                <p>{add.mobile_number}</p>
              </div>
              <div>
                <p className="pt-2">
                  {add.near_by_address}, {add.full_address}, {add.state}
                </p>
              </div>
              <p className="font-semibold pt-2">PinCode: {add.pin_code}</p>
            </div>
          ))}
        </div>
      </div>

  );
};

export default UserAddresses;
