import axios from "axios";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel2 = () => {
  const [carouselone, setCarousel] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchCarousel = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL_NODE}api/admin/sliders/bottom`
      );
      setCarousel(response.data.data); // Make sure to access response.data
    } catch (error) {
      console.error("Error fetching carousel data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCarousel();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => (
      <div className="mt-4">
        <ul className="flex justify-center space-x-2">{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div className="w-3 h-3 rounded-full cursor-pointer bg-gray-300 hover:bg-gray-500"></div>
    ),
  };

  return (
    <div className="sliderContainer bg-blue-50 w-full lg:p-4 sm:pt-1 sm:pb-1 md:p-6 lg:pt-0 lg:pl-10 lg:pr-10 relative sm:h[150px]">
      {loading ? (
        <div className="rounded-xl w-full bg-gray-300 bg-center border shadow-md max-sm:h-[150px] max-md:h-[200px] lg:h-[250px] animate-pulse"></div>
      ) : (
        <Slider {...settings}>
          {carouselone.map((carousel, index) => (
            <div key={index}>
              <img
                src={`${
                  process.env.REACT_APP_PUBLIC_API_BASE_URL_NODE
                }uploads/sliders/${carousel.image}?t=${new Date().getTime()}`}
                alt={carousel.link}
                className="rounded-xl w-full  bg-center border shadow-md max-sm:h-[150px] max-md:h-[200px] lg:h-[370px]"
                // onClick={() => navigate(`${carousel.link}`)}
              />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

// Custom Next Arrow Component
const NextArrow = ({ onClick }) => {
  return (
    <button
      className="absolute top-1/2 right-2 transform -translate-y-1/2 p-2 bg-gray-200 text-[#b22222] rounded-full hover:bg-[#b22222] hover:text-white transition-colors duration-300 hidden sm:inline "
      onClick={onClick}
    >
      &#10095;
    </button>
  );
};

// Custom Prev Arrow Component
const PrevArrow = ({ onClick }) => {
  return (
    <button
      className="absolute top-1/2 left-2 transform -translate-y-1/2 p-2 bg-gray-200 text-[#b22222] rounded-full hover:bg-[#b22222] hover:text-white transition-colors duration-300 z-10 hidden sm:inline "
      onClick={onClick}
    >
      &#10094;
    </button>
  );
};

export default Carousel2;
