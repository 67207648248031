import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FaTag, FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

const SubCategoryListingProduct = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchProductsBySubcategory = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://uvfolderking.com/suresop/api/getProductBySubCatgory/${id}`
      );
      setProducts(response.data.products);
      console.log("response sub product", response.data.products);
      // console.log("product is ", response.data.products);
    } catch (err) {
      setError("Failed to fetch products. Please try again later.");
      console.error("Error fetching products by subcategory:", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id) fetchProductsBySubcategory(id);
  }, [id]);
  const renderStars = (rating, maxRating = 5) => {
    return Array.from({ length: maxRating }, (_, index) => {
      const currentRating = index + 1;
      if (rating >= currentRating) {
        return <FaStar key={index} className="text-yellow-500" />;
      } else if (rating >= currentRating - 0.5) {
        return <FaStarHalfAlt key={index} className="text-yellow-500" />;
      } else {
        return <FaRegStar key={index} className="text-gray-400" />;
      }
    });
  };

  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold text-center mb-6">
        Subcategory Products
      </h2>
      {products.length === 0 ? (
        <p className="text-center">No products found for this subcategory.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {products.map((product) => (
            <div
              key={product.id}
              className="group relative bg-white shadow-md border-2 rounded-lg overflow-hidden transform transition-transform duration-500 hover:scale-105 pt-2"
              onClick={() => handleProductClick(product.id)}
            >
              <Link to={`/products/${product.id}`}>
                <div className="relative">
                  <img
                    src={`https://uvfolderking.com/suresop/storage/app/public/${product.featured_image}`}
                    alt={product.name}
                    className="object-cover w-full h-48"
                  />
                </div>
                <div className="p-4">
                  <p className="text-start text-lg font-semibold mb-2">
                    {product.name.slice(0, 40)}
                  </p>
                  <p className="text-sm text-gray-600 mb-2">
                    {product.description.slice(0, 50)}
                  </p>
                  {/* <div className="flex text-sm text-gray-600 mb-2">{renderStars(product.rating)}</div> */}
                  {/* <div className="flex flex-col">
                    <div className="flex items-center space-x-2 mb-2">
                       <span className="text-lg font-bold text-green-500">₹{product.sale_price}</span>
                      <span className="text-sm text-gray-500 line-through">₹{product.old_price}</span>
                    </div>
                  </div> */}
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SubCategoryListingProduct;
