import React, { useState, useEffect } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { addproduct } from "../../redux/action";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import becomeSeller from "../images/Become.png";

const ImageGallery = () => {
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [first, setFirst] = useState([]);
  const [showAd, setShowAd] = useState(true); 
  const navigate = useNavigate();

  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();
  console.log("image", becomeSeller);
  // Shuffle and get random products
  const getRandomProducts = (products) => {
    const product = [...products];
    return product.sort(() => 0.5 - Math.random()).slice(0, 4);
  };

  const randomProducts = getRandomProducts(products);
  

  useEffect(() => {
    dispatch(addproduct());
    setLoadingProducts(false); // Assume Redux will update quickly
  }, [dispatch]);
  // REACT_APP_PUBLIC_API_BASE_URL_NODE

  const fetchCategories = async () => {
    setLoadingCategories(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/categories/getallcategory`
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoadingCategories(false);
    }
  };
  const fetchFirstCampaign = async () => {
    try {
      console.log("fetch1");
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL_NODE}api/admin/campaign/page/home/location/1`
      );
      const data = response.data.data;

      if (Array.isArray(data) && data.length > 0) {
        const lastElement = data[data.length - 1]; // Access the last element
        console.log("Last Element:", lastElement);
        setFirst(lastElement); // Set the last element as state
      } else {
        console.log("Data is not an array or is empty");
      }
      console.log("response 1", response.data.data);
    } catch (error) {
      console.error("error in get first campaign", error);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchFirstCampaign();
    
  }, []);
  const handleAdClose = () => {
    setShowAd(false); // Hide the ad
  };
  const getRandomCategory = (category) => {
    return category.sort(() => 0.5 - Math.random()).slice(0, 4);
  };

  const randomCategory = getRandomCategory(categories);
  const handleNavigation = () => {
    window.location.href = "https://vendorsure.suresop.com/";
  };
  return (
    <div className="container  mx-auto  px-4 md:px-5 lg:px-10 py-5 shadow-sm">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
        {/* Column 2: Top Categories */}
        <div className="bg-white shadow-lg rounded-lg p-4 lg:p-6">
          <h3 className="text-xl font-bold mb-4 text-gray-700">
            Top Categories
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2">
            {loadingCategories
              ? [...Array(4)].map((_, index) => (
                  <Skeleton key={index} height={200} className="rounded-lg" />
                ))
              : randomCategory.map((category) => (
                  <div
                    key={category.id}
                    className="group border bg-white rounded-lg p-2 pb-1 hover:shadow-xl transition-shadow duration-300"
                  >
                    <Link to={`/category/${category.id}`}>
                      <img
                      src={`${process.env.REACT_APP_PUBLIC_CATEGORY_IMAGES_BASE_URL}uploads/categories/${category.image}`}
                      alt={category.categorie_name}
                        className="w-full h-48 object-cover rounded-lg transition-transform duration-300 group-hover:scale-105"
                      />
                      <div className="text-center w-full py-2">
                        <p className="text-lg font-semibold">{category.categorie_name}</p>
                      </div>
                    </Link>
                  </div>
                ))}
          </div>
        </div>

        {/* Column 1: Top Pick */}
        <div className="bg-white shadow-lg rounded-lg p-4 lg:p-6">
          <h3 className="text-xl font-bold mb-4 text-gray-700">Top Pick</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2">
            {loadingCategories
              ? [...Array(4)].map((_, index) => (
                  <Skeleton key={index} height={200} className="rounded-lg" />
                ))
              : randomProducts.map((product) => (
                  <div
                    key={product.id}
                    className="group border bg-white rounded-lg p-2 pb-1 hover:shadow-xl transition-shadow duration-300"
                  >
                    <Link to={`/products/${product.id}`}>
                      <img
                        src={`https://uvfolderking.com/suresop/storage/app/public/${product.featured_image}`}
                        alt={product.name}
                        className="w-full h-48 object-cover rounded-lg transition-transform duration-300 group-hover:scale-105"
                      />
                      <div className="text-center w-full py-2">
                        <p className="text-lg font-semibold">{product.name.slice(0,20)}</p>
                      </div>
                    </Link>
                  </div>
                ))}
          </div>
        </div>

        {/* Column 3: See More Button */}
        <div className="flex flex-col w-full items-center justify-center bg-white shadow-lg rounded-lg p-4 ">
          {loadingCategories ? (
            <Skeleton height={50} width={150} className="rounded-lg" />
          ) : (
            <div
              className="w-full h-auto bg-cover bg-center aspect-[466/627]  flex items-end justify-center relative"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_PUBLIC_API_BASE_URL_NODE}uploads/campaign/${first.image})`,
              }}
            >
              <img
                src={`${process.env.REACT_APP_PUBLIC_API_BASE_URL_NODE}uploads/campaign/${first.image}`}
                alt=""
                className="w-full h-full"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
