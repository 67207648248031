import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const OrderSuccessPage = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5); // Initialize countdown to 5 seconds

  useEffect(() => {
    // Set up a timer to decrement the countdown every second
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(timer); // Clear timer when countdown reaches 0
          navigate("/account/myorders"); // Redirect after countdown finishes
        }
        return prev - 1;
      });
    }, 1000);

    // Cleanup timer on component unmount
    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <div
      className="order-success-container"
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f8f9fa",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <div style={{ maxWidth: "600px" }}>
        <h1
          style={{ color: "#28a745", fontSize: "2.5rem", marginBottom: "20px" }}
        >
          🎉 Order Placed Successfully!
        </h1>
        <p
          style={{
            fontSize: "1.2rem",
            color: "#6c757d",
            marginBottom: "30px",
          }}
        >
          Thank you for your purchase. Your order has been placed and is being
          processed.
        </p>
        <div>
          <button
            onClick={() => navigate("/")}
            style={{
              margin: "10px",
              padding: "15px 30px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "1rem",
            }}
          >
            Go to Home
          </button>
          <button
            onClick={() => navigate("/account/myorders")}
            className="animated-bg"
            style={{
              margin: "10px",
              padding: "15px 30px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "1rem",
              color: "#fff",
            }}
          >
            View My Orders
          </button>
        </div>
        <p
          style={{
            marginTop: "20px",
            fontSize: "1rem",
            color: "#6c757d",
          }}
        >
          You will be redirected in {countdown} seconds...
        </p>
      </div>
      <style jsx>{`
        .animated-bg {
          animation: colorChange 8s infinite, fadeInOut 1s infinite alternate;
        }

        @keyframes colorChange {
          0% {
            background-color: #ffffff; /* White */
          }
          50% {
            background-color: rgb(171, 35, 31); /* Purple */
          }
          100% {
            background-color: #ffffff; /* White */
          }
        }

        @keyframes fadeInOut {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default OrderSuccessPage;
